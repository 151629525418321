import React from 'react'

export default function Calendar(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
      <path fill="currentColor" d="M1036,693 C1036.55228,693 1037,693.447715 1037,694 L1037,694 L1037,698 L1040,698 L1040,710 L1024,710 L1024,698 L1027,698 L1027,694 C1027,693.487164 1027.38604,693.064493 1027.88338,693.006728 L1028,693 C1028.55228,693 1029,693.447715 1029,694 L1029,694 L1029,698 L1035,698 L1035,694 C1035,693.487164 1035.38604,693.064493 1035.88338,693.006728 Z M1028,706 C1027.44772,706 1027,706.447715 1027,707 C1027,707.552285 1027.44772,708 1028,708 C1028.55228,708 1029,707.552285 1029,707 C1029,706.447715 1028.55228,706 1028,706 Z M1032,706 C1031.44772,706 1031,706.447715 1031,707 C1031,707.552285 1031.44772,708 1032,708 C1032.55228,708 1033,707.552285 1033,707 C1033,706.447715 1032.55228,706 1032,706 Z M1036,706 C1035.44772,706 1035,706.447715 1035,707 C1035,707.552285 1035.44772,708 1036,708 C1036.55228,708 1037,707.552285 1037,707 C1037,706.447715 1036.55228,706 1036,706 Z M1028,702 C1027.44772,702 1027,702.447715 1027,703 C1027,703.552285 1027.44772,704 1028,704 C1028.55228,704 1029,703.552285 1029,703 C1029,702.447715 1028.55228,702 1028,702 Z M1032,702 C1031.44772,702 1031,702.447715 1031,703 C1031,703.552285 1031.44772,704 1032,704 C1032.55228,704 1033,703.552285 1033,703 C1033,702.447715 1032.55228,702 1032,702 Z M1036,702 C1035.44772,702 1035,702.447715 1035,703 C1035,703.552285 1035.44772,704 1036,704 C1036.55228,704 1037,703.552285 1037,703 C1037,702.447715 1036.55228,702 1036,702 Z" transform="translate(-1024 -693)" />
    </svg>


  )
}
